* {
  box-sizing: border-box;
  font-family: "Cousine", monospace;
  margin: 0;
}

/* Define 1rem */
:root {
  font-size: 12px;
  line-height: 1.5;
  --bg-color: hsl(36, 100%, 98%);
}

body {
  background-color: var(--bg-color);
}

#leftbar {
  display: flex;
  padding: 2em 0 2em 0em;
}

/* Borders must match to have proper alignment */
#statuses,
textarea {
  border: 2px solid;
}

#statuses {
  border-color: transparent;
  padding: 0.5em;
}

.status-container {
  position: relative;
  width: 18px;
  height: 18px;
  text-align: center;
}

.status-container > svg {
  position: absolute;
  top: 0;
}

textarea {
  border-color: lightgray;
  padding: 0.5em;
  white-space: pre;
  resize: none;
  min-width: 40ch;
  font-size: 1rem;
  line-height: 1.5;
}

#timelines {
  margin: 3em;
  flex-grow: 1;
  position: relative;
}

#add-timezone {
  text-align: center;
  margin-bottom: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

#add-timezone select {
  padding: 0.5em;
  background-color: white;
  border: 1px solid darkgray;
  border-radius: 8px;
  height: 100%;
}

button {
  background-color: pink;
  border: none;
  border-radius: 8px;
  font-size: 2em;
  aspect-ratio: 1;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Fix button text not appearing vertically centered for X and + */
button > span {
  height: 1em;
}

button:hover {
  cursor: pointer;
  background-color: hotpink;
}

button:active {
  background-color: pink;
}

#add-button {
  margin-left: 0.5em;
}

svg {
  display: block;
}

svg > * {
  pointer-events: none;
}

.modal-backdrop {
  position: fixed;
  opacity: 0.6;
  background-color: black;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  visibility: hidden;
}

.modal {
  position: fixed;
  background-color: white;
  opacity: 1;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  padding: 1.5em;
  border-radius: 8px;
  display: flex;
  visibility: hidden;
}

.modal-right {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}

.modal-title {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  margin-top: 1em;
}

td {
  padding: 0.3em 0.5em;
}

td:first-child {
  font-weight: bold;
  padding-left: 0;
}

td:last-child {
  padding-right: 0;
}

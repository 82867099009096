* {
  box-sizing: border-box;
  margin: 0;
  font-family: Cousine, monospace;
}

:root {
  --bg-color: #fffbf5;
  font-size: 12px;
  line-height: 1.5;
}

body {
  background-color: var(--bg-color);
}

#leftbar {
  padding: 2em 0 2em 0;
  display: flex;
}

#statuses, textarea {
  border: 2px solid;
}

#statuses {
  border-color: #0000;
  padding: .5em;
}

.status-container {
  text-align: center;
  width: 18px;
  height: 18px;
  position: relative;
}

.status-container > svg {
  position: absolute;
  top: 0;
}

textarea {
  white-space: pre;
  resize: none;
  border-color: #d3d3d3;
  min-width: 40ch;
  padding: .5em;
  font-size: 1rem;
  line-height: 1.5;
}

#timelines {
  flex-grow: 1;
  margin: 3em;
  position: relative;
}

#add-timezone {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  display: flex;
}

#add-timezone select {
  background-color: #fff;
  border: 1px solid #a9a9a9;
  border-radius: 8px;
  height: 100%;
  padding: .5em;
}

button {
  aspect-ratio: 1;
  background-color: pink;
  border: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-size: 2em;
  display: flex;
}

button > span {
  height: 1em;
}

button:hover {
  cursor: pointer;
  background-color: #ff69b4;
}

button:active {
  background-color: pink;
}

#add-button {
  margin-left: .5em;
}

svg {
  display: block;
}

svg > * {
  pointer-events: none;
}

.modal-backdrop {
  opacity: .6;
  visibility: hidden;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal {
  opacity: 1;
  visibility: hidden;
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5em;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}

.modal-right {
  flex-direction: column;
  margin-left: 1em;
  display: flex;
}

.modal-title {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  margin-top: 1em;
}

td {
  padding: .3em .5em;
}

td:first-child {
  padding-left: 0;
  font-weight: bold;
}

td:last-child {
  padding-right: 0;
}

/*# sourceMappingURL=index.9f1ec7db.css.map */
